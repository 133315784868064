(function ($) {

	'use strict'

	$.fn.fadingGalleryCarousel = function () {

        this.each(function (i, item) {

            var gui = $(item);

            init(gui);
        });

        return this;
    }

    function init(gui) {

			var slidesSelector = gui.find('[data-role="slide"]');
        var slides = slidesSelector.map(function () { return $(this) });

    	var state = {
    		currentSlideIndex: 0,
    		slides: slides,
    		autoSlideTransitionInterval: null,
            hasMoreThanOneSlide: slides.length > 1,
				type: gui.data('gallery-type') ? gui.data('gallery-type') : 'normal',
    	}

    	gui.data('state', state);

			// Only show the previous and next arrows if we have more than 1 slide.
			gui.find('[data-role="btn-previous"]').toggle(state.hasMoreThanOneSlide);
			gui.find('[data-role="btn-next"]').toggle(state.hasMoreThanOneSlide);

    	initClickHandlers(gui);
    	transitionToSlide(gui, 0, false);
    }

    function initClickHandlers(gui) {

    	gui.find('[data-role="btn-previous"]').click(function () {
            transitionToPreviousSlide(gui);
        });

        gui.find('[data-role="btn-next"]').click(function () {
            transitionToNextSlide(gui);
        });
    }

    function transitionToPreviousSlide(gui) {

    	var state = gui.data('state');
    	var targetSlideIndex = wrapSlideIndex(gui, state.currentSlideIndex - 1);

			transitionToSlide(gui, targetSlideIndex, true);
    }

    function transitionToNextSlide(gui) {

    	var state = gui.data('state');
    	var targetSlideIndex = wrapSlideIndex(gui, state.currentSlideIndex + 1);

			transitionToSlide(gui, targetSlideIndex, true);
    }

    function transitionToSlide(gui, targetSlideIndex, animate) {

    	var state = gui.data('state');

			if (state.type === 'preview') {
				// remove old previous and next styles
				if (state.nextSlideIndex !== undefined) {
					state.slides[state.nextSlideIndex].removeAttr('style');
				}

				if (state.previousSlideIndex !== undefined) {
					state.slides[state.previousSlideIndex].removeAttr('style');
				}
			}

    	var currentSlide = state.slides[state.currentSlideIndex];
    	var targetSlide = state.slides[targetSlideIndex];

    	if (animate) {
				if (state.type !== 'preview') {
	    		currentSlide.fadeOut();
				}
	    	targetSlide.fadeIn();
	    } else {
				if (state.type !== 'preview') {
	    		currentSlide.hide();
				}
				targetSlide.attr('style','display: block;');
	    }

    	state.currentSlideIndex = targetSlideIndex;

			gui.find('[data-role="current-slide-number"]').html(state.currentSlideIndex + 1);
			gui.find('[data-role="number-of-slides"]').html(state.slides.length);

			if (state.type === 'preview') {
				state.nextSlideIndex = wrapSlideIndex(gui, targetSlideIndex + 1);
				state.previousSlideIndex = wrapSlideIndex(gui, targetSlideIndex - 1);

				// apply next slide styles
				if (state.nextSlideIndex !== state.currentSlideIndex) {
					state.slides[state.nextSlideIndex].attr('style', 'display: block; left: 100%; opacity: 0.5;');
				}

				// apply previous slide styles
				if ((state.previousSlideIndex !== state.currentSlideIndex) && (state.previousSlideIndex !== state.nextSlideIndex)) {
					state.slides[state.previousSlideIndex].attr('style', 'display: block; left: auto; opacity: 0.5; right: 100%;');
				}
			}
    }

    function wrapSlideIndex(gui, slideIndex) {

    	var state = gui.data('state');

    	if (slideIndex < 0) {
    		slideIndex = state.slides.length - 1;
    	} else if (slideIndex >= state.slides.length) {
    		slideIndex = 0;
    	}

    	return slideIndex;
    }

})(jQuery);