(function ($) {

    'use strict'

    $.fn.airportMap = function () {

        this.each(function (i, item) {

            var gui = $(item);

            init(gui);
        });

        return this;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////// Initialisation ///////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function init(gui) {

        loadMapData(gui, function () {

            initGuiState(gui);
            initViewport(gui);
            initRenderLoop(gui);
            initMapPicker(gui);
            initZoomButtons(gui);
            initExpandSideBarButtons(gui);
            initTwoFingerScrollMessage(gui);
            initMapMouseEvents(gui);
            initMapTouchEvents(gui);
            initWindowResizeHandler(gui);
            initZonePickers(gui);
            initCategoryNavigator(gui);
            initSubCategoryViewer(gui);
            initFeatureViewer(gui);

            start(gui);
        });
    }

    function initGuiState(gui) {

        var guiState = {
            selectedMapSpecId: null,
            scrollAnchor: {
                x: 0,
                y: 0,
                anchorIsPlaced: false
            },
            activeZone: 'all',
            zonePickerChangeEventHandlers: [],
            viewingSubCategoryId: null,
            viewingFeatureId: null,
            highlightedFeatureId: null,
            isHighlightingAllFeatureMapPoints: false
        }

        gui.data('guiState', guiState); 
    }

    function initViewport(gui) {

        var html = '<div data-role="map-container" style="position: relative;"></div>';

        var viewportElement = gui.find('[data-role="map-viewport"]');
        viewportElement.html(html);

        calculateViewportSizeAndPoisition(gui);
    }

    function initRenderLoop(gui) {

        var renderFunction = createRenderFunction(gui);
        gui.data('renderFunction', renderFunction);

        window.requestAnimationFrame(renderFunction);
    }

    function initMapPicker(gui) {

        var mapSpecsById = gui.data('mapSpecsById');
        var mapPickerElement = gui.find('[data-role="map-picker"]');

        // Create the HTML for the map pickers.
        var mapSpecIds = getHashTableKeys(mapSpecsById);
        var html = '';

        for (var i = 0; i < mapSpecIds.length; i++) {

            var mapSpecId = mapSpecIds[i];
            var mapSpec = mapSpecsById[mapSpecId];

            html += '<button class="btn btn-secondary" data-role="map-picker" data-map-spec-id="' + mapSpec.id + '">' + mapSpec.shortName + '</button>';
        }

        mapPickerElement.html(html);

        // Wire up the click events for the map pickers.
        mapPickerElement.find('[data-role="map-picker"]').click(function (e) {

            var mapSpecId = $(this).attr('data-map-spec-id');
            selectMap(gui, mapSpecId);
            e.preventDefault();
        });
    }

    function initZoomButtons(gui) {

        gui.find('[data-role="zoom-button"]').click(function () {

            var scaleFactorMultiplier = parseFloat($(this).attr('data-scale-factor-multiplier'));
            zoomMap(gui, scaleFactorMultiplier, true, false);
        });
    }

    function initExpandSideBarButtons(gui) {

        gui.find('[data-role="expand-side-bar-button"]').click(function () {
            expandSideBar(gui);
        });

        gui.find('[data-role="contract-side-bar-button"]').click(function () {
            contractSideBar(gui);
        });
    }

    function initTwoFingerScrollMessage(gui) {

        var mapViewportElement = getMapViewportElement(gui)[0];
        var twoFingerScrollMessage = gui.find('[data-role="two-finger-scroll-message"]');

        mapViewportElement.addEventListener('touchstart', function (e) {
            twoFingerScrollMessage.show();
        });
    }

    function initMapMouseEvents(gui) {

        var mapViewportElement = getMapViewportElement(gui)[0];

        mapViewportElement.addEventListener('mousedown', function (e) {
            handleMapPressDownEvent(gui, e.pageX, e.pageY, 'click'); 
        });

        document.addEventListener('mouseup', function (e) {
            handleMapPressUpEvent(gui);
        });

        mapViewportElement.addEventListener('mousemove', function (e) {
            if (handleMapPressMoveEvent(gui, e.pageX, e.pageY)) {
                e.preventDefault();
            }
        });

        mapViewportElement.addEventListener('wheel', function (e) {
           zoomMap(gui, e.deltaY < 0 ? 1.1 : 0.9, false, false);
        });
    }

    function initMapTouchEvents(gui) {

        var guiState = gui.data('guiState');
        var mapViewportElement = getMapViewportElement(gui)[0];
        var twoFingerScrollMessage = gui.find('[data-role="two-finger-scroll-message"]');

        mapViewportElement.addEventListener('touchstart', function (e) {
            var touch = e.changedTouches[0];
            guiState.scrollAnchorTouchIdentifier = touch.identifier;
            handleMapPressDownEvent(gui, touch.pageX, touch.pageY);             
        });

        mapViewportElement.addEventListener('touchend', function (e) {
            handleMapPressUpEvent(gui);
        });

        mapViewportElement.addEventListener('touchmove', function (e) {
            if (e.touches.length == 2) {
                twoFingerScrollMessage.hide();

                var touch = findTouchByIdentifier(e.touches, guiState.scrollAnchorTouchIdentifier); 
                if (touch != null && handleMapPressMoveEvent(gui, touch.pageX, touch.pageY)) {
                    e.preventDefault();
                }
            } else {
                twoFingerScrollMessage.show();
            }
        });
    }

    function initWindowResizeHandler(gui) {

        $(window).resize(function() {
            log(gui, 'Handling window resize.');
            handleViewportResize(gui);
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////// Start Up //////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function start(gui) {

        var mapSpecsById = gui.data('mapSpecsById');
        var started = false;
        var qs = parseQueryString();

        log(gui, 'Query string: ' + JSON.stringify(qs));

        // Allow a feature ID to be specified on the query-string.
        var featureId = qs['featureId'];
        if (featureId != null) {

            var mapSpec = findMapSpecForFeatureId(gui, featureId);
            if (mapSpec != null) {
                log(gui, 'Starting with feature ID.');
                selectMap(gui, mapSpec.id);
                viewFeatureInFeatureViewer(gui, featureId);
                selectAllMapPointsForFeature(gui, featureId);
                expandSideBar(gui);
                started = true;
            }
        } 

        // Allow a map spec ID to be specified on the query-string.
        if (!started) {
            var mapSpecId = qs['mapSpecId'];
            if (mapSpecId != null) {
                var mapSpec = mapSpecsById[mapSpecId];
                if (mapSpec != null) {
                    log(gui, 'Starting with map spec ID.');
                    selectMap(gui, mapSpec.id);
                    started = true;
                }
            }
        }

        // If we aren't starting in a fancy way, then just show the configured initial map.
        if (!started) {
            log(gui, 'Starting normally.');
            selectInitialMap(gui);
        }
    }

    function selectInitialMap(gui) {

        var config = gui.data('config');
        var mapSpecsById = gui.data('mapSpecsById');

        var mapSpecId = config.initialMapSpecId;
        var mapSpec = mapSpecsById[mapSpecId];

        if (mapSpec == null) {
            mapSpecId = getFirstKeyFromHashTable(mapSpecsById);
            mapSpec = mapSpecsById[mapSpecId];
        }

        if (mapSpec != null) {
            selectMap(gui, mapSpec.id);
        } else {
            log(gui, 'No maps available.');
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////// General //////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function updateActiveMapSpecNameLabels(gui) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        gui.find('[data-role="active-map-spec-name-label"]').text(mapSpec.name);
    }

    function findTouchByIdentifier(touches, identifier) {
        var touch = null;
        for (var i = 0; i < touches.length; i++) {
            var potentialTouch = touches[i];
            if (potentialTouch.identifier == identifier) {
                touch = potentialTouch;
                break;
            }
        }
        return touch;
    }

    function expandSideBar(gui) {
        gui.addClass('sidebar-expanded');
        handleViewportResize(gui);
    }

    function contractSideBar(gui) {
        gui.removeClass('sidebar-expanded');
        handleViewportResize(gui);
    }

    function scrollGuiIntoView(gui) {
        $('html, body').animate({ scrollTop: gui.offset().top }, 300);
    }

    function updateMapPickerButtonHighlights(gui) {

        var guiState = gui.data('guiState');
        gui.find('[data-role="map-picker"]').removeClass('active');
        gui.find('[data-role="map-picker"][data-map-spec-id="' + guiState.selectedMapSpecId + '"]').addClass('active');
    }

    function handleMapPressDownEvent(gui, x, y) {

        var guiState = gui.data('guiState');
        var targetMapMetrics = gui.data('targetMapMetrics');
        var viewportPosition = gui.data('viewportPosition');
        var viewportSize = gui.data('viewportSize');

        guiState.scrollAnchor.x = x;
        guiState.scrollAnchor.y = y;
        guiState.scrollAnchor.anchorIsPlaced = true;

        var svgSpaceClickCoord = {
            x: Math.round(((x - viewportPosition.x - (viewportSize.width / 2)) / targetMapMetrics.scaleFactor) + targetMapMetrics.scrollOffset.x),
            y: Math.round(((y - viewportPosition.y - (viewportSize.height / 2)) / targetMapMetrics.scaleFactor) + targetMapMetrics.scrollOffset.y)
        }

        log(gui, 'SVG click at ("x": ' + svgSpaceClickCoord.x + ', "y": ' + svgSpaceClickCoord.y + ').');
    }

    function handleMapPressUpEvent(gui) {

        var guiState = gui.data('guiState');
        guiState.scrollAnchor.anchorIsPlaced = false;
    }

    function handleMapPressMoveEvent(gui, x, y) {

        var guiState = gui.data('guiState');

        if (guiState.scrollAnchor.anchorIsPlaced) {

            var delta = {
                x: x - guiState.scrollAnchor.x,
                y: y - guiState.scrollAnchor.y
            }

            guiState.scrollAnchor.x = x;
            guiState.scrollAnchor.y = y;

            scrollMap(gui, delta);
        }

        return guiState.scrollAnchor.anchorIsPlaced;
    }

    function selectMap(gui, mapSpecId) {

        var mapSpecsById = gui.data('mapSpecsById');
        
        var mapSpec = mapSpecsById[mapSpecId];
        log(gui, 'Selected map: ' + mapSpec.name);

        // Update the GUI state.
        var guiState = gui.data('guiState'); 
        guiState.selectedMapSpecId = mapSpec.id;

        gui.data('featuresByCategoryId', convertArrayToIndexTable(mapSpec.features, 'categoryId'));

        // Create the target map metrics.
        var targetMapMetrics = {
            scaleFactor: calculateMinimumScaleFactorForMapSpec(gui, mapSpec),//mapSpec.initialScaleFactor,
            scrollOffset: {
                x: 0,
                y: 0
            },
            mustRedraw: true,
            tweenTranslation: false,
            tweenScaleFactor: false,
            slowScaleTween: false
        }

        // Don't let the inital scale factor fall below the 'min inital scale factor'.
        // This prevents the map being initially really zoomed out on mobile.
        log(gui, 'Unmodifgied initial scale factor: ' + targetMapMetrics.scaleFactor);

        if (targetMapMetrics.scaleFactor < mapSpec.minInitialScaleFactor) {
            targetMapMetrics.scaleFactor = mapSpec.minInitialScaleFactor;
        }

        gui.data('targetMapMetrics', targetMapMetrics);

        // Update the GUI.
        updateActiveMapSpecNameLabels(gui);
        closeSubCategoryViewer(gui);
        closeFeatureViewer(gui);
        resetZonePickers(gui);
        outputSvgAndMapPointsForMapSpec(gui, mapSpec);
        setupSvgElementEventsForMapSpec(gui, mapSpec);
        setupMapPointEvents(gui);
        populateCategoryNavigator(gui);
        centerMapOnPoint(gui, mapSpec.initialCentrePoint, false);
        updateMapPickerButtonHighlights(gui);
    }

    function setupMapPointEvents(gui) {

        gui.find('[data-role="map-point"]').each(function (i, element) {
            var mapPoint = $(element);
            var clickables = mapPoint.find('[data-clickable="true"]');
            var featureId = mapPoint.attr('data-feature-id');
            var mapPointIndex = mapPoint.attr('data-map-point-index');
            var feature = findFeatureById(gui, featureId);
            
            ////////////////
            /*var label = mapPoint.find('.label');
            if (label.length > 0) {
                var offset = label.offset();
                var bottom = label.offset().top + label.outerHeight(true);
                log(gui, 'Bottom: ' + bottom);
            }*/
            /*if (featureId == '80') {
                var label = mapPoint.find('.label');
                var left = label.css('left');
                if (left == 'auto') {
                    left = '0';
                }
                var top = label.css('top');
                if (top == 'auto') {
                    top = '0';
                }

                label.css({'left': (parseInt(left) + 50) + 'px'});

                //label.offset({ left: left + 10, top: top });
            }*/
            //////////////

            clickables.click(createMapPointClickHandler(gui, feature, mapPointIndex));
        });         
    }

    function calculateBoundingRectangleAroundFeatures(gui, features, filterByActiveZone) {

        var guiState = gui.data('guiState');

        var rect = null;

        for (var featureIndex = 0; featureIndex < features.length; featureIndex++) {
            var feature = features[featureIndex];

            for (var pointIndex = 0; pointIndex < feature.points.length; pointIndex++) {
                var point = feature.points[pointIndex];

                if (!filterByActiveZone || point.zone == guiState.activeZone || guiState.activeZone == 'all') {

                    if (rect == null) {

                        rect = {
                            from: { x: point.x, y: point.y },
                            to: { x: point.x, y: point.y }
                        }

                    } else {

                        if (point.x < rect.from.x) {
                            rect.from.x = point.x;
                        }

                        if (point.y < rect.from.y) {
                            rect.from.y = point.y;
                        }

                        if (point.x > rect.to.x) {
                            rect.to.x = point.x;
                        }

                        if (point.y > rect.to.y) {
                            rect.to.y = point.y;
                        }
                    }
                }
            }
        }

        return rect;
    }
    
    function outputSvgAndMapPointsForMapSpec(gui, mapSpec) {

        var svgsByMapSpecId = gui.data('svgsByMapSpecId');

        var svg = svgsByMapSpecId[mapSpec.id];
        var mapPointsHtml = buildMapPointsHtml(mapSpec.features);

        var mapContainerElement = getMapContainerElement(gui);
        mapContainerElement.html(svg + mapPointsHtml);

        var svgElement = mapContainerElement.find('svg');
        svgElement.css({ width: '100%', height: '100%' });

        // Stop unwanted image dragging in Firefox.
        svgElement.on("dragstart", function() {
            return false;
        });

        setupDebugEventsOnSvgElements(gui, svgElement[0]);
    }

    function buildMapPointsHtml(features) {

        var mapPointsHtml = '';
        for (var featureIndex = 0; featureIndex < features.length; featureIndex++) {
            var feature = features[featureIndex];
 
            for (var mapPointIndex = 0; mapPointIndex < feature.points.length; mapPointIndex++) {

                var point = feature.points[mapPointIndex];

                var hasLabel = point.label != null && point.label != '';

                var iconsHtml = '';
                for (var iconIndex = 0; iconIndex < point.icons.length; iconIndex++) {
                    var icon = point.icons[iconIndex];
                    iconsHtml += '<div class="icon map-point-icon-' + icon + '" data-clickable="true"></div>';
                }

                mapPointsHtml += 
                    '<div id="map-point-' + feature.id + '-' + mapPointIndex + '" ' +
                    'data-role="map-point" ' + 
                    'data-feature-id="' + feature.id + '" ' + 
                    'data-map-point-index="' + mapPointIndex + '" ' + 
                    'style="position: absolute;" ' + 
                    'class="map-point ' + (point.icons.length > 1 ? 'multi-icon' : '') + ' ' + (hasLabel ? 'has-label' : '') + '">';

                mapPointsHtml += '<div class="pin-container"><span class="icon misc-icon-pin"></span></div>';

                if (hasLabel) {
                    var labelIsClickable = point.labelStyle == 'gate';
                    mapPointsHtml += 
                        '<div class="label ' + point.labelStyle + '"><span class="label-inner" data-clickable="' + labelIsClickable + '">' + point.label + '</span></div>';
                }

                mapPointsHtml += '<div class="icons-container">' + iconsHtml + '</div>';

                mapPointsHtml += '</div>';
            }
        }

      return mapPointsHtml;
    }

    function createRenderFunction(gui) {

        return function () {
            render(gui);
        }
    }

    function render(gui) {
      
        var guiState = gui.data('guiState');
        var targetMapMetrics = gui.data('targetMapMetrics');
        var currentMapMetrics = gui.data('currentMapMetrics');
        var mapSpecsById = gui.data('mapSpecsById');
        var viewportSize = gui.data('viewportSize');

        if (targetMapMetrics != null) {

            var mapContainerElement = getMapContainerElement(gui);
            var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

            var mustRedraw = targetMapMetrics.mustRedraw;
            targetMapMetrics.mustRedraw = false;
            
            if (currentMapMetrics == null) {

                currentMapMetrics = {
                    scaleFactor: 0,
                    scrollOffset: {
                        x: 0,
                        y: 0
                    }
                }

                gui.data('currentMapMetrics', currentMapMetrics);

                mustRedraw = true;
            }

            var translationHasChanged = false;
            var scaleFactorHasChanged = false;

            if (targetMapMetrics.scrollOffset.x != currentMapMetrics.scrollOffset.x ||
                targetMapMetrics.scrollOffset.y != currentMapMetrics.scrollOffset.y) {

                if (targetMapMetrics.tweenTranslation) {
                    currentMapMetrics.scrollOffset.x = lerp(currentMapMetrics.scrollOffset.x, targetMapMetrics.scrollOffset.x, 0.4);
                    currentMapMetrics.scrollOffset.y = lerp(currentMapMetrics.scrollOffset.y, targetMapMetrics.scrollOffset.y, 0.4);

                    // Combat some of the pixel swimming when the current metrics approach the target metrics by
                    // giving the lerp a "gimme". i.e. that's close enough, you're done now.
                    currentMapMetrics.scrollOffset.x = gimme(currentMapMetrics.scrollOffset.x, targetMapMetrics.scrollOffset.x, 0.5);
                    currentMapMetrics.scrollOffset.y = gimme(currentMapMetrics.scrollOffset.y, targetMapMetrics.scrollOffset.y, 0.5);

                } else {
                    currentMapMetrics.scrollOffset.x = targetMapMetrics.scrollOffset.x;
                    currentMapMetrics.scrollOffset.y = targetMapMetrics.scrollOffset.y;
                }

                translationHasChanged = true;
            }

            if (targetMapMetrics.scaleFactor != currentMapMetrics.scaleFactor) {

                if (targetMapMetrics.tweenScaleFactor) {
                    currentMapMetrics.scaleFactor = lerp(currentMapMetrics.scaleFactor, targetMapMetrics.scaleFactor, targetMapMetrics.slowScaleTween ? 0.1 : 0.8);
                    currentMapMetrics.scaleFactor = gimme(currentMapMetrics.scaleFactor, targetMapMetrics.scaleFactor, targetMapMetrics.slowScaleTween ? 0.008 : 0.01);
                } else {
                    currentMapMetrics.scaleFactor = targetMapMetrics.scaleFactor;
                }

                scaleFactorHasChanged = true;
            }

            if (mustRedraw || scaleFactorHasChanged) {

                mapContainerElement.width(Math.ceil(mapSpec.baseDimensions.width * currentMapMetrics.scaleFactor));
                mapContainerElement.height(Math.ceil(mapSpec.baseDimensions.height * currentMapMetrics.scaleFactor));
            }

            if (mustRedraw || translationHasChanged || scaleFactorHasChanged) {

                var mapContainerOffset = {
                    x: (viewportSize.width / 2) - (currentMapMetrics.scrollOffset.x * currentMapMetrics.scaleFactor),
                    y: (viewportSize.height / 2) - (currentMapMetrics.scrollOffset.y * currentMapMetrics.scaleFactor)
                }

                mapContainerElement.css({ 
                    marginLeft: mapContainerOffset.x + 'px',
                    marginTop: mapContainerOffset.y + 'px'
                });
            }

            if (mustRedraw || scaleFactorHasChanged) {

                for (var featureIndex = 0; featureIndex < mapSpec.features.length; featureIndex++) {
                    var feature = mapSpec.features[featureIndex];

                    for (var pointIndex = 0; pointIndex < feature.points.length; pointIndex++) {
                        var point = feature.points[pointIndex];

                        var mapPointElement = $('#map-point-' + feature.id + '-' + pointIndex);
                        mapPointElement.css({ 
                            left: ((point.x * currentMapMetrics.scaleFactor) - (mapPointElement.width() / 2)) + 'px', 
                            top: ((point.y * currentMapMetrics.scaleFactor) - (mapPointElement.height())) + 'px'
                        });
                    }
                }
            }

            gui.toggleClass('show-map-point-labels', currentMapMetrics.scaleFactor >= mapSpec.minScaleFactorForMapPointLabels);
        }

        var renderFunction = gui.data('renderFunction');

        window.requestAnimationFrame(renderFunction);
    }

    function getMapViewportElement(gui) {

        return gui.find('[data-role="map-viewport"]');
    }

    function getMapContainerElement(gui) {

        return gui.find('[data-role="map-container"]');
    }

    function calculateViewportSizeAndPoisition(gui) {

        var mapViewportElement = getMapViewportElement(gui);

        var viewportSize = {
            width: mapViewportElement.width(),
            height: mapViewportElement.height()
        }

        var viewportPosition = {
            x: mapViewportElement.offset().left,
            y: mapViewportElement.offset().top
        }

        gui.data('viewportSize', viewportSize);
        gui.data('viewportPosition', viewportPosition);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////// Map Translation and Scaling ////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function centerMapOnPoint(gui, point, tweenTranslation) {

        var targetMapMetrics = gui.data('targetMapMetrics');

        targetMapMetrics.scrollOffset.x = point.x;
        targetMapMetrics.scrollOffset.y = point.y;

        clampTargetScrollOffset(gui);

        targetMapMetrics.tweenTranslation = tweenTranslation;
    }

    function scrollMap(gui, delta) {

        var targetMapMetrics = gui.data('targetMapMetrics');
        if (targetMapMetrics != null) {

            targetMapMetrics.scrollOffset.x -= (delta.x / targetMapMetrics.scaleFactor);
            targetMapMetrics.scrollOffset.y -= (delta.y / targetMapMetrics.scaleFactor);
            targetMapMetrics.tweenTranslation = false;

            clampTargetScrollOffset(gui);
        }
    }

    function zoomMap(gui, scaleFactorMultiplier, tweenScaleFactor, slowScaleTween) {

        var targetMapMetrics = gui.data('targetMapMetrics');
        if (targetMapMetrics != null) {

            var oldTargetScaleFactor = targetMapMetrics.scaleFactor;
            targetMapMetrics.scaleFactor *= scaleFactorMultiplier;
            targetMapMetrics.tweenScaleFactor = tweenScaleFactor;
            targetMapMetrics.slowScaleTween = slowScaleTween;

            clampTargetScaleFactor(gui);
            clampTargetScrollOffset(gui);
        }
    }

    function clampTargetScrollOffset(gui) {

        var guiState = gui.data('guiState');
        var targetMapMetrics = gui.data('targetMapMetrics');
        var viewportSize = gui.data('viewportSize');
        var mapSpecsById = gui.data('mapSpecsById');

        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        var halfMapSpaceViewportSize = {
            width: (viewportSize.width / 2) / targetMapMetrics.scaleFactor,
            height: (viewportSize.height / 2) / targetMapMetrics.scaleFactor
        }

        var minScrollOffset = {
            x: halfMapSpaceViewportSize.width,
            y: halfMapSpaceViewportSize.height
        }

        var maxScrollOffset = {
            x: mapSpec.baseDimensions.width - halfMapSpaceViewportSize.width,
            y: mapSpec.baseDimensions.height - halfMapSpaceViewportSize.height
        }

        targetMapMetrics.scrollOffset.x = clamp(targetMapMetrics.scrollOffset.x, minScrollOffset.x, maxScrollOffset.x);
        targetMapMetrics.scrollOffset.y = clamp(targetMapMetrics.scrollOffset.y, minScrollOffset.y, maxScrollOffset.y);
    }

    function clampTargetScaleFactor(gui) {

        var guiState = gui.data('guiState');
        var targetMapMetrics = gui.data('targetMapMetrics');
        var viewportSize = gui.data('viewportSize');
        var mapSpecsById = gui.data('mapSpecsById');

        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        var minScaleFactor = calculateMinimumScaleFactorForMapSpec(gui, mapSpec);
        if (targetMapMetrics.scaleFactor < minScaleFactor) {
            targetMapMetrics.scaleFactor = minScaleFactor;
        }
    }

    function calculateMinimumScaleFactorForMapSpec(gui, mapSpec) {

        var viewportSize = gui.data('viewportSize');

        var minimumWidthScaleFactor = viewportSize.width / mapSpec.baseDimensions.width;
        var minimumHeightScaleFactor = viewportSize.height / mapSpec.baseDimensions.height;
        var minimumScaleFactor = max(minimumWidthScaleFactor, minimumHeightScaleFactor);

        return minimumScaleFactor;
    }

    function handleViewportResize(gui) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];
        var originalViewportSize = gui.data('viewportSize');
        var targetMapMetrics = gui.data('targetMapMetrics');

        var originalTopLeftMapSpacePoint = {
            x: targetMapMetrics.scrollOffset.x - ((originalViewportSize.width / 2) / targetMapMetrics.scaleFactor),
            y: targetMapMetrics.scrollOffset.y - ((originalViewportSize.height / 2) / targetMapMetrics.scaleFactor)
        }

        calculateViewportSizeAndPoisition(gui);

        var newViewportSize = gui.data('viewportSize');

        var minScaleFactor = calculateMinimumScaleFactorForMapSpec(gui, mapSpec);
        if (targetMapMetrics.scaleFactor < minScaleFactor) {
            targetMapMetrics.scaleFactor = minScaleFactor;
        }

        var newCentrePoint = {
            x: originalTopLeftMapSpacePoint.x + ((newViewportSize.width / 2) / targetMapMetrics.scaleFactor),
            y: originalTopLeftMapSpacePoint.y + ((newViewportSize.height / 2) / targetMapMetrics.scaleFactor)
        }

        centerMapOnPoint(gui, newCentrePoint, false);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////// Data Loading ///////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function loadMapData(gui, callback) {

        // Load the main map specs JSON file.
        var mapDataJsonUrl = gui.attr('data-map-data-json-url');

        $.getJSON(mapDataJsonUrl, function (mapData) {

            gui.data('config', mapData.config);

            gui.data('categories', mapData.categories);
            gui.data('categoriesById', convertArrayToHashTable(mapData.categories, 'id'));

            gui.data('subCategories', mapData.subCategories);
            gui.data('subCategoriesById', convertArrayToHashTable(mapData.subCategories, 'id'));
            gui.data('subCategoriesByCategoryId', convertArrayToIndexTable(mapData.subCategories, 'categoryId'));

            gui.data('mapSpecs', mapData.mapSpecs);
            gui.data('mapSpecsById', convertArrayToHashTable(mapData.mapSpecs, 'id'));

            loadSvgs(gui, function () {
                log(gui, 'Loaded map data.');
                callback();
            });
        });
    }

    function loadSvgs(gui, callback) {

        var mapSpecs = gui.data('mapSpecs');

        // Load each of the SVGs specified in the map specs.
        var svgsByMapSpecId = {};
        gui.data('svgsByMapSpecId', svgsByMapSpecId);
        var mapSpecIndex = 0;

        var recursor = function () {

            var mapSpec = mapSpecs[mapSpecIndex];
            loadSvgFromUrl(mapSpec.svgUrl, function (svg) {

                svgsByMapSpecId[mapSpec.id] = svg;
                log(gui, 'Loaded SVG: ' + mapSpec.svgUrl);

                mapSpecIndex++;
                if (mapSpecIndex < mapSpecs.length) {
                    recursor();
                } else {
                    // All done, return to the caller.
                    callback();
                }
            });
        }

        recursor();
    }

    function loadSvgFromUrl(svgUrl, callback) {

        $.ajax({
            url: svgUrl,
            success: callback,
            dataType: 'text'
          });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////// Data Finding, Filtering and Sorting ////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function findMapSpecForFeatureId(gui, featureId) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');

        var mapSpecIds = getHashTableKeys(mapSpecsById);

        var matchedMapSpec = null;
        for (var i = 0; i < mapSpecIds.length; i++) {
            var mapSpecId = mapSpecIds[i];
            var mapSpec = mapSpecsById[mapSpecId];

            var matchFound = false;
            for (var j = 0; j < mapSpec.features.length; j++) {
                var feature = mapSpec.features[j];
                if (feature.id == featureId) {
                    matchFound = true;
                    break;
                }
            }

            if (matchFound) {
                matchedMapSpec = mapSpec;
                break;
            }
        }

        return matchedMapSpec;
    }

    function findUtilisedCategoriesForMapSpec(gui, mapSpec) {

        var categoriesById = gui.data('categoriesById');

        var lookup = {};

        for (var i = 0; i < mapSpec.features.length; i++) {

            var feature = mapSpec.features[i];
            if (feature.categoryId != 0 && lookup[feature.categoryId] == null) {
                lookup[feature.categoryId] = true;
            }
        }

        var utilisedCategoryIds = getHashTableKeys(lookup);
        var categories = [];

        for (var i = 0; i < utilisedCategoryIds.length; i++) {
            var categoryId = utilisedCategoryIds[i];
            var category = categoriesById[categoryId];
            categories.push(category);
        }

        return categories;
    }

    function findFeatureById(gui, featureId) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        var applicableFeature = null;

        for (var i = 0; i < mapSpec.features.length; i++) {
            var feature = mapSpec.features[i];
            if (feature.id == featureId) {
                applicableFeature = feature;
                break;
            }
        }

        return applicableFeature;
    }

    function findFeaturesInCategory(gui, categoryId) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        var filteredFeatures = [];

        for (var i = 0; i < mapSpec.features.length; i++) {
            var feature = mapSpec.features[i];
            if (feature.categoryId == categoryId) {
                filteredFeatures.push(feature);
            }
        }

        return filteredFeatures;
    }

    function findFeaturesInSubCategory(gui, subCategoryId) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        var filteredFeatures = [];

        for (var i = 0; i < mapSpec.features.length; i++) {
            var feature = mapSpec.features[i];
            if (arrayContains(feature.subCategoryIds, subCategoryId)) {
                filteredFeatures.push(feature);
            }
        }

        return filteredFeatures;
    }

    function filterCategoriesByZone(gui, categories, zone) {

        var featuresByCategoryId = gui.data('featuresByCategoryId');

        var filteredCategories = [];

        for (var categoryIndex = 0; categoryIndex < categories.length; categoryIndex++) {
            var category = categories[categoryIndex];
            
            var features = featuresByCategoryId[category.id];
            features = filterFeaturesByZone(gui, features, zone);

            if (features.length > 0) {
                filteredCategories.push(category);
            }
        }

        return filteredCategories;
    }

    function filterSubCategoriesByZone(gui, subCategories, zone) {

        var filteredSubCategories = [];

        for (var subCategoryIndex = 0; subCategoryIndex < subCategories.length; subCategoryIndex++) {
            var subCategory = subCategories[subCategoryIndex];

            var features = findFeaturesInSubCategory(gui, subCategory.id);
            features = filterFeaturesByZone(gui, features, zone);

            if (features.length > 0) {
                filteredSubCategories.push(subCategory);
            }
        }
        
        return filteredSubCategories;
    }

    function filterFeaturesByZone(gui, features, zone) {

        var filteredFeatures = [];

        for (var featureIndex = 0; featureIndex < features.length; featureIndex++) {
            var feature = features[featureIndex];
    
            var includeFeature = false;
            for (var pointIndex = 0; pointIndex < feature.points.length; pointIndex++) {
                var point = feature.points[pointIndex];
                if (zone == 'all' || point.zone == zone) {
                    includeFeature = true;
                    break;
                }
            }

            if (includeFeature) {
                filteredFeatures.push(feature);
            }
        }

        return filteredFeatures;
    }

    function filterFeaturesByCategory(gui, features, categoryId) {

        var filteredFeatures = [];

        for (var featureIndex = 0; featureIndex < features.length; featureIndex++) {
            var feature = features[featureIndex];
    
            if (feature.categoryId == categoryId) {
                filteredFeatures.push(feature);
            }
        }

        return filteredFeatures;
    }

    function filterFeaturesByHasNoSubCategory(gui, features) {

        var filteredFeatures = [];

        for (var featureIndex = 0; featureIndex < features.length; featureIndex++) {
            var feature = features[featureIndex];
    
            if (feature.subCategoryIds.length == 0) {
                filteredFeatures.push(feature);
            }
        }

        return filteredFeatures;
    }

    function sortCategories(categories) {

        categories.sort(function(a, b) {
            return a.sortIndex < b.sortIndex ? -1 : a.sortIndex > b.sortIndex ? 1 : 0;
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////// Zone Pickers ////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function initZonePickers(gui) {

        var guiState = gui.data('guiState');
        var zonePickers = gui.find('[data-role="zone-picker"]');

        zonePickers.click(function () {
            var zonePicker = $(this);
            var zone = zonePicker.attr('data-zone');

            guiState.activeZone = zone;

            syncroniseZonePickers(gui);
            raiseZonePickerChangeEvent(gui);
        });

        syncroniseZonePickers(gui);
    }

    function resetZonePickers(gui) {

        var guiState = gui.data('guiState');

        gui.find('[data-role="zone-picker"]').removeClass('active');
        gui.find('[data-role="zone-picker"][data-zone="all"]').addClass('active');
        guiState.activeZone = 'all';
    }

    function syncroniseZonePickers(gui) {

        var guiState = gui.data('guiState');
        var zonePickers = gui.find('[data-role="zone-picker"]');

        zonePickers.each(function (i, item) {
            var zonePicker = $(item);
            var zone = zonePicker.attr('data-zone');
            if (zone == guiState.activeZone) {
                zonePicker.addClass('active');
            } else {
                zonePicker.removeClass('active');
            }
        });
    }

    function registerZonePickerChangeEventHandler(gui, eventHandler) {
        
        var guiState = gui.data('guiState');

        guiState.zonePickerChangeEventHandlers.push(eventHandler);
    }

    function raiseZonePickerChangeEvent(gui) {
        
        var guiState = gui.data('guiState');

        for (var i = 0; i < guiState.zonePickerChangeEventHandlers.length; i++) {
            var eventHandler = guiState.zonePickerChangeEventHandlers[i];
            eventHandler();
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////// Category Navigator /////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function initCategoryNavigator(gui) {

        var navigatorElement = gui.find('[data-role="category-navigator"]');
        var searchTextbox = navigatorElement.find('[data-role="category-navigator-search-textbox"]');
        var clearSearchButton = navigatorElement.find('[data-role="category-navigator-clear-search-button"]');

        searchTextbox.keyup(function () {
            searchCategoryNavigator(gui);
        });

        clearSearchButton.click(function () {
            searchTextbox.val('');
            searchCategoryNavigator(gui);
        });

        registerZonePickerChangeEventHandler(gui, function () {
            searchCategoryNavigator(gui);
        });
    }

    function populateCategoryNavigator(gui) {

        var navigatorElement = gui.find('[data-role="category-navigator"]');
        var searchTextbox = navigatorElement.find('[data-role="category-navigator-search-textbox"]');

        searchTextbox.val('');

        searchCategoryNavigator(gui);
    }

    function searchCategoryNavigator(gui) {

        var navigatorElement = gui.find('[data-role="category-navigator"]');
        var searchTextboxContainer = navigatorElement.find('[data-role="category-navigator-search-textbox-container"]');
        var searchTextbox = navigatorElement.find('[data-role="category-navigator-search-textbox"]');
        var categoryList = navigatorElement.find('[data-role="category-navigator-category-list"]');
        var searchResultsList = navigatorElement.find('[data-role="category-navigator-search-results-list"]');
        var noSearchResultsMessage = navigatorElement.find('[data-role="category-navigator-no-search-results-message"]');

        var searchTerm = $.trim(searchTextbox.val());

        if (searchTerm == '') {

            searchTextboxContainer.removeClass('active');

            var categoriesListHtml = buildCategoryNavigatorCategoryListHtml(gui);
            categoryList.html(categoriesListHtml);

            categoryList.show();
            searchResultsList.hide();
            noSearchResultsMessage.hide();

        } else {

            searchTextboxContainer.addClass('active');

            var searchResults = gatherCategoryNavigatorSearchResults(gui, searchTerm);
            var html = buildCategoryNavigatorSearchResultsHtml(gui, searchResults);
            searchResultsList.html(html); 

            categoryList.hide();
            searchResultsList.show();     
            noSearchResultsMessage.toggle(searchResults.length == 0);
        }

        navigatorElement.find('[data-role="sub-category-selector"]').click(function (e) {
            var subCategoryId = $(this).attr('data-sub-category-id');
            viewSubCategoryInSubCategoryViewer(gui, subCategoryId);
            e.preventDefault();
        });

        navigatorElement.find('[data-role="feature-selector"]').click(function (e) {
            var featureId = $(this).attr('data-feature-id');
            selectAllMapPointsForFeature(gui, featureId);
            viewFeatureInFeatureViewer(gui, featureId);
            e.preventDefault();
        });
    }

    function gatherCategoryNavigatorSearchResults(gui, searchTerm) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        var subCategories = gui.data('subCategories');
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];
        var features = mapSpec.features;

        var searchResults = [];

        searchTerm = searchTerm.toLowerCase();

        subCategories = filterSubCategoriesByZone(gui, subCategories, guiState.activeZone);
        features = filterFeaturesByZone(gui, features, guiState.activeZone);

        // Search across the sub-categories.
        for (var i = 0; i < subCategories.length; i++) {
            var subCategory = subCategories[i];
            if (subCategory.name.toLowerCase().indexOf(searchTerm) != -1) {

                var searchResult = {
                    type: 'sub-category',
                    subCategory: subCategory,
                    name: subCategory.name
                }

                searchResults.push(searchResult);
            }
        }

        // Search across the features.
        for (var i = 0; i < features.length; i++) {
            var feature = features[i];
            if (feature.name.toLowerCase().indexOf(searchTerm) != -1) {

                var searchResult = {
                    type: 'feature',
                    feature: feature,
                    name: feature.name
                }

                searchResults.push(searchResult);
            }
        }

        searchResults.sort(function(a, b) {
            var nameA = a.name.toLowerCase();
            var nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            } else if (nameA > nameB) {
                return 1;
            } else {
                return 0;
            }
        });

        return searchResults;
    }

    function buildCategoryNavigatorCategoryListHtml(gui) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        var subCategoriesByCategoryId = gui.data('subCategoriesByCategoryId');
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        var categories = findUtilisedCategoriesForMapSpec(gui, mapSpec);
        categories = filterCategoriesByZone(gui, categories, guiState.activeZone);
        sortCategories(categories);

        var html = '<ul>';
        for (var i = 0; i < categories.length; i++) {
            var category = categories[i];

            var subCategories = subCategoriesByCategoryId[category.id] || [];
            subCategories = filterSubCategoriesByZone(gui, subCategories, guiState.activeZone);

            var features = mapSpec.features;
            features = filterFeaturesByHasNoSubCategory(gui, features);
            features = filterFeaturesByCategory(gui, features, category.id);
            
            if (subCategories.length > 0 || features.length > 0) {
                html += '<li>';
                html += category.name;

                // Build the sub category list.
                html += '<ul>';
                for (var j = 0; j < subCategories.length; j++) {
                    var subCategory = subCategories[j];
                    html += 
                        '<li>' + 
                        '<a href="#" data-role="sub-category-selector" data-sub-category-id="' + subCategory.id + '">' + 
                        '<span class="icon map-point-icon-' + subCategory.icon + '"></span> ' +
                        subCategory.name + 
                        '</a>' + 
                        '</li>';
                }
                html += '</ul>';

                // Build the feature list.
                html += '<ul>';
                for (var j = 0; j < features.length; j++) {
                    var feature = features[j];
                    html += 
                        '<li>' + 
                        '<a href="#" data-role="feature-selector" data-feature-id="' + feature.id + '">' + 
                        '<span class="icon map-point-icon-' + feature.icon + '"></span> ' +
                        feature.name + 
                        '</a>' + 
                        '</li>';
                }
                html += '</ul>';

                html +='</li>';
            }
        }
        html += '</ul>';

        return html;
    }

    function buildCategoryNavigatorSearchResultsHtml(gui, searchResults) {

        var html = '<ul>';
        for (var i = 0; i < searchResults.length; i++) {
            var searchResult = searchResults[i];
            html += '<li>';

            if (searchResult.type == 'sub-category') {

                var subCategory = searchResult.subCategory;
                html += 
                    '<a href="#" data-role="sub-category-selector" data-sub-category-id="' + subCategory.id + '">' + 
                    '<span class="icon map-point-icon-' + subCategory.icon + '"></span> ' +
                    subCategory.name + 
                    '</a>';
            
            } else if (searchResult.type == 'feature') {

                var feature = searchResult.feature;
                html += 
                    '<a href="#" data-role="feature-selector" data-feature-id="' + feature.id + '">' + 
                    '<span class="icon map-point-icon-' + feature.icon + '"></span> ' +
                    feature.name + 
                    '</a>';
            }

            html += '</li>';
        }
        html += '</ul>';

        return html;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////// Sub Category Viewer ////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function initSubCategoryViewer(gui) {

        var viewerElement = gui.find('[data-role="sub-category-viewer"]');

        viewerElement.find('[data-role="close-button"]').click(function (e) {
            closeSubCategoryViewer(gui);
            e.preventDefault();
        });

        registerZonePickerChangeEventHandler(gui, function () {
            updateSubCategoryViewerFeatureList(gui);
        });
    }

    function viewSubCategoryInSubCategoryViewer(gui, subCategoryId) {

        var guiState = gui.data('guiState');
        var subCategoriesById = gui.data('subCategoriesById');
        var subCategory = subCategoriesById[subCategoryId];

        guiState.viewingSubCategoryId = subCategoryId;

        var iconHtml = '<span class="icon map-point-icon-' + subCategory.icon + '"></span>';

        var viewerElement = gui.find('[data-role="sub-category-viewer"]');
        viewerElement.find('[data-role="sub-category-viewer-icon"]').html(iconHtml);
        viewerElement.find('[data-role="sub-category-viewer-name"]').text(subCategory.name);

        updateSubCategoryViewerFeatureList(gui);

        viewerElement.addClass('active');
    }

    function updateSubCategoryViewerFeatureList(gui) {

        var guiState = gui.data('guiState');
        var viewerElement = gui.find('[data-role="sub-category-viewer"]');
        var noSearchResultsMessage = gui.find('[data-role="sub-category-viewer-no-search-results-message"]');

        if (guiState.viewingSubCategoryId != null) {

            var features = gatherSubCategoryViewerSearchResults(gui);

            var featureListHtml = buildSubCategoryViewerFeatureListHtml(gui, features);
            viewerElement.find('[data-role="sub-category-viewer-feature-list"]').html(featureListHtml);

            noSearchResultsMessage.toggle(features.length == 0);

            viewerElement.find('[data-role="feature-selector"]').click(function (e) {
                var featureId = $(this).attr('data-feature-id');
                selectAllMapPointsForFeature(gui, featureId);
                viewFeatureInFeatureViewer(gui, featureId);
                e.preventDefault();
            });

            selectAllMapPointsForFeatures(gui, features);
        }
    }

    function gatherSubCategoryViewerSearchResults(gui) {

        var guiState = gui.data('guiState');

        var features = findFeaturesInSubCategory(gui, guiState.viewingSubCategoryId);
        features = filterFeaturesByZone(gui, features, guiState.activeZone);

        return features;
    }

    function buildSubCategoryViewerFeatureListHtml(gui, features) {

        var html = '<ul>';
        for (var i = 0; i < features.length; i++) {
            var feature = features[i];
            html += 
                '<li>' + 
                '<a href="#" data-role="feature-selector" data-feature-id="' + feature.id + '">' + 
                '<span class="icon map-point-icon-' + feature.icon + '"></span> ' +
                feature.name + 
                '</a>' + 
                '</li>';
        }
        html += '</ul>';

        return html;
    }

    function closeSubCategoryViewer(gui) {

        var guiState = gui.data('guiState');
        guiState.viewingSubCategoryId = null;

        var viewerElement = gui.find('[data-role="sub-category-viewer"]');
        viewerElement.removeClass('active');
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////// Feature Viewer ///////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function initFeatureViewer(gui) {

        var guiState = gui.data('guiState');

        var viewerElement = gui.find('[data-role="feature-viewer"]');

        viewerElement.find('[data-role="close-button"]').click(function (e) {
            closeFeatureViewer(gui);
            e.preventDefault();
        });

        viewerElement.find('[data-role="feature-viewer-view-all-map-points-link"]').click(function () {
            selectAllMapPointsForFeature(gui, guiState.viewingFeatureId);
            $(this).hide();
        });
    }

    function viewFeatureInFeatureViewer(gui, featureId) {

        var config = gui.data('config');
        var guiState = gui.data('guiState');
        
        guiState.viewingFeatureId = featureId;

        var feature = findFeatureById(gui, featureId);

        var iconHtml = '<span class="icon map-point-icon-' + feature.icon + '"></span>';

        var imageHtml = '';
        if (feature.imageUrl != '') {
            imageHtml = '<img src="' + feature.imageUrl + '" />';
        }

        var viewerElement = gui.find('[data-role="feature-viewer"]');
        viewerElement.find('[data-role="feature-viewer-icon"]').html(iconHtml);
        viewerElement.find('[data-role="feature-viewer-name"]').text(feature.name);
        viewerElement.find('[data-role="feature-viewer-tag-line"]').text(feature.tagLine);
        viewerElement.find('[data-role="feature-viewer-image"]').html(imageHtml);
        viewerElement.find('[data-role="feature-viewer-description"]').html(feature.description);
        viewerElement.find('[data-role="feature-viewer-opening-hours-container"]').toggle(feature.showOpeningHours);
        viewerElement.find('[data-role="feature-viewer-opening-hours"]').html(feature.openingHours != "" ? feature.openingHours : "");
        viewerElement.find('[data-role="feature-viewer-opening-hours-container"]').toggle(feature.openingHours != "");
        viewerElement.find('[data-role="feature-viewer-view-feature-link"]')
            .toggle(feature.url != '')
            .attr('href', feature.url);
        
        viewerElement.find('[data-role="feature-viewer-view-all-map-points-link"]')
            .toggle(feature.points.length > 1 && (guiState.highlightedFeatureId != featureId || !guiState.isHighlightingAllFeatureMapPoints))
            .text(feature.viewAllLinkText || 'View All');

        viewerElement.addClass('active');
    }

    function closeFeatureViewer(gui) {

        var guiState = gui.data('guiState');

        var viewerElement = gui.find('[data-role="feature-viewer"]');
        viewerElement.removeClass('active');
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////// Map Point Selection //////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function selectAllMapPointsForFeatures(gui, features) {

        var guiState = gui.data('guiState');

        deactivateAllFeatureMapPoints(gui);
        scrollFeaturesIntoView(gui, features, true);
        clearSvgElementHighlightsForAllFeatures(gui);
        enableSvgElementHighlightsForFeatures(gui, features);

        guiState.highlightedFeatureId = null;
        guiState.isHighlightingAllFeatureMapPoints = true;
    }

    function selectAllMapPointsForFeature(gui, featureId) {

        var guiState = gui.data('guiState');

        var feature = findFeatureById(gui, featureId);

        deactivateAllFeatureMapPoints(gui);
        activateAllMapPointsForFeature(gui, feature);

        clearSvgElementHighlightsForAllFeatures(gui);
        enableSvgElementHighlightsForFeature(gui, feature);

        scrollFeaturesIntoView(gui, [feature], false);

        guiState.highlightedFeatureId = featureId;
        guiState.isHighlightingAllFeatureMapPoints = true;
    }

    function selectSingleMapPointForFeature(gui, featureId, mapPointIndex) {

        var guiState = gui.data('guiState');

        var feature = findFeatureById(gui, featureId);

        deactivateAllFeatureMapPoints(gui);
        activateAllMapPointsForFeature(gui, feature);
        //activateSingleMapPointForFeature(gui, feature, mapPointIndex);

        clearSvgElementHighlightsForAllFeatures(gui);
        enableSvgElementHighlightsForFeature(gui, feature);
        //enableSvgElementHighlightsForFeatureMapPoint(gui, feature, mapPointIndex);

        scrollSingleMapPointFeatureIntoView(gui, feature, mapPointIndex);
        
        guiState.highlightedFeatureId = featureId;
        guiState.isHighlightingAllFeatureMapPoints = false;
    }

    function activateSingleMapPointForFeature(gui, feature, mapPointIndex) {
        
        var mapPoint = gui.find('[data-role="map-point"][data-feature-id="' + feature.id + '"]:eq(' + mapPointIndex + ')');
        mapPoint.addClass('active');
    }

    function activateAllMapPointsForFeature(gui, feature) {
        
        var mapPoints = gui.find('[data-role="map-point"][data-feature-id="' + feature.id + '"]');
        mapPoints.addClass('active');
    }

    function deactivateAllFeatureMapPoints(gui) {

        var guiState = gui.data('guiState');

        var mapPoints = gui.find('[data-role="map-point"]');
        mapPoints.removeClass('active');

        guiState.highlightedFeatureId = null;
        guiState.isHighlightingAllFeatureMapPoints = false;
    }

    function scrollFeaturesIntoView(gui, features, filterByActiveZone) {

        // We have to defer execution of this until the next frame, so that the adjustments
        // made for the viewport resize can take effect.
        requestAnimationFrame(function () {

            var guiState = gui.data('guiState');
            var targetMapMetrics = gui.data('targetMapMetrics');
            var viewportSize = gui.data('viewportSize');
            var mapSpecsById = gui.data('mapSpecsById');
            var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

            var rect = calculateBoundingRectangleAroundFeatures(gui, features, filterByActiveZone);
            if (rect != null) {

                var centrePoint = {
                    x: lerp(rect.from.x, rect.to.x , 0.5),
                    y: lerp(rect.from.y, rect.to.y , 0.5)
                };

                var requiredScaleFactor = 0;

                if (centrePoint.x != rect.from.x) {

                    var padding = 50;

                    requiredScaleFactor = min(
                        (viewportSize.width / 2) / (centrePoint.x - rect.from.x + padding), 
                        (viewportSize.height / 2) / (centrePoint.y - rect.from.y + padding));

                } else {

                    requiredScaleFactor = mapSpec.idealViewFeatureScaleFactor;
                }

                // Handle the case where a group of map points are clustered together, resulting in the a scale factor
                // which would zoom in more than neccessary.
                if (requiredScaleFactor > mapSpec.idealViewFeatureScaleFactor) {
                    requiredScaleFactor = mapSpec.idealViewFeatureScaleFactor;
                }

                var minScaleFactor = calculateMinimumScaleFactorForMapSpec(gui, mapSpec);
                if (requiredScaleFactor < minScaleFactor) {
                    requiredScaleFactor = minScaleFactor;
                }

                if (targetMapMetrics.scaleFactor != requiredScaleFactor) {

                    targetMapMetrics.scaleFactor = requiredScaleFactor;
                    targetMapMetrics.tweenScaleFactor = false;

                    centerMapOnPoint(gui, centrePoint, false);

                } else {

                    centerMapOnPoint(gui, centrePoint, true);
                }
            }
        });
    }

    function scrollSingleMapPointFeatureIntoView(gui, feature, mapPointIndex) {

        // We have to defer execution of this until the next frame, so that the adjustments
        // made for the viewport resize can take effect.
        requestAnimationFrame(function () {

            var guiState = gui.data('guiState');
            var targetMapMetrics = gui.data('targetMapMetrics');
            var mapSpecsById = gui.data('mapSpecsById');
            var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

            var mapPoint = feature.points[mapPointIndex];
            var centrePoint = {
                x: mapPoint.x,
                y: mapPoint.y
            }

            // Detemine if we should zoom into the point to get a clearer view.
            // This is usual for shops, but not so much for gates.
            var shouldZoom = mapPoint.labelStyle != 'gate';

            if (targetMapMetrics.scaleFactor != mapSpec.idealViewFeatureScaleFactor && shouldZoom) {

                targetMapMetrics.scaleFactor = mapSpec.idealViewFeatureScaleFactor;
                targetMapMetrics.tweenScaleFactor = false;

                centerMapOnPoint(gui, centrePoint, false);

            } else {

                centerMapOnPoint(gui, centrePoint, true);
            }
        });
    }

    function createMapPointClickHandler(gui, feature, mapPointIndex) {

        return function () {
            console.log('Feature click.');
            expandSideBar(gui);
            selectSingleMapPointForFeature(gui, feature.id, mapPointIndex);
            viewFeatureInFeatureViewer(gui, feature.id); 
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////// SVG Manipulation ///////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function setupSvgElementEventsForMapSpec(gui, mapSpec) {

        for (var featureIndex = 0; featureIndex < mapSpec.features.length; featureIndex++) {
            var feature = mapSpec.features[featureIndex];
            
            for (var mapPointIndex = 0; mapPointIndex < feature.points.length; mapPointIndex++) {
                var point = feature.points[mapPointIndex];

                var node = document.getElementById(point.svgElementId);
                if (node != null) {
                    var clickHandler = createMapPointClickHandler(gui, feature, mapPointIndex);
                    node.addEventListener('click', clickHandler);
                }
            }
        }
    }

    function enableSvgElementHighlightsForFeatures(gui, features) {

        for (var featureIndex = 0; featureIndex < features.length; featureIndex++) {
            var feature = features[featureIndex];

            enableSvgElementHighlightsForFeature(gui, feature);
        }
    }

    function enableSvgElementHighlightsForFeature(gui, feature) {

        for (var pointIndex = 0; pointIndex < feature.points.length; pointIndex++) {
            var point = feature.points[pointIndex];

            if (point.svgElementId != '') {
                enableSvgElementHighlight(gui, point.svgElementId);
            }
        }
    }

    function enableSvgElementHighlightsForFeatureMapPoint(gui, feature, mapPointIndex) {

        var point = feature.points[mapPointIndex];

        if (point.svgElementId != '') {
            enableSvgElementHighlight(gui, point.svgElementId);
        }
    }

    function clearSvgElementHighlightsForAllFeatures(gui) {

        var guiState = gui.data('guiState');
        var mapSpecsById = gui.data('mapSpecsById');
        var mapSpec = mapSpecsById[guiState.selectedMapSpecId];

        for (var featureIndex = 0; featureIndex < mapSpec.features.length; featureIndex++) {
            var feature = mapSpec.features[featureIndex];

            for (var pointIndex = 0; pointIndex < feature.points.length; pointIndex++) {
                var point = feature.points[pointIndex];

                if (point.svgElementId != '') {
                    clearSvgElementHighlight(gui, point.svgElementId);
                }
            }
        }
    }

    function enableSvgElementHighlight(gui, svgElementId) {

        forEachPolygonInSvgElement(gui, svgElementId, function (node) {
            var oldClass = node.getAttribute('class');
            node.setAttribute('class', 'highlighted-svg-element');
            node.setAttribute('old-class', oldClass);
        });
    }

    function clearSvgElementHighlight(gui, svgElementId) {

        forEachPolygonInSvgElement(gui, svgElementId, function (node) {
            var oldClass = node.getAttribute('old-class');
            if (oldClass != '' && oldClass != null) {
                node.setAttribute('class', oldClass);
            }
        });
    }

    function forEachPolygonInSvgElement(gui, svgElementId, callback) {

        var recursor = function (node) {
            for (var i = 0; i < node.childNodes.length; i++) {
                var childNode = node.childNodes[i];
                if (childNode.nodeName == 'g' || 
                    childNode.nodeName == 'polygon' || 
                    childNode.nodeName == 'path' ||
                    childNode.nodeName == 'polyline' ||
                    childNode.nodeName == 'rect' ||
                    childNode.nodeName == 'line') {
                    callback(childNode);
                }

                recursor(childNode);
            }
        }

        var svgElement = document.getElementById(svgElementId);
        if (svgElement != null) {
            recursor(svgElement);
        } else {
            log(gui, 'Unable to find SVG element "' + svgElementId + '".');
        }
    }

    function setupDebugEventsOnSvgElements(gui, rootSvgElement) {

        var recursor = function (node) {
            for (var i = 0; i < node.childNodes.length; i++) {
                var childNode = node.childNodes[i];
                if (childNode.nodeName == 'g' || 
                    childNode.nodeName == 'polygon' || 
                    childNode.nodeName == 'path' || 
                    childNode.nodeName == 'polyline' ||
                    childNode.nodeName == 'rect') {
                    childNode.addEventListener('click', makeSvgElementDebugClickHandler(gui, childNode));
                }

                recursor(childNode);
            }
        }

        recursor(rootSvgElement);
    }

    function makeSvgElementDebugClickHandler(gui, svgElement) {

        return function () {
            log(gui, 'Clicked SVG element: ' + svgElement.id);
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////// Util ////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////

    function parseQueryString() {

        var lookup = {};
        var kvps = location.search.substr(1).split('&');
        for (var i = 0; i < kvps.length; i++) {
            var kvp = kvps[i];
            var parts = kvp.split('=');
            lookup[parts[0]] = parts[1];
        }

        return lookup;
    }

    function convertArrayToHashTable(array, keyPropertyName) {

        var hashTable = {};
        for (var i = 0; i < array.length; i++) {
            var item = array[i];
            hashTable[item[keyPropertyName]] = item;
        }

        return hashTable;
    }

    function convertArrayToIndexTable(array, keyPropertyName) {

        var indexTable = {};
        for (var i = 0; i < array.length; i++) {
            var item = array[i];

            var key = item[keyPropertyName];
            var indexItem = indexTable[key];
            if (indexItem == null) {
                indexItem = [];
                indexTable[key] = indexItem;
            }

            indexItem.push(item);
        }

        return indexTable;
    }

    function arrayContains(array, value) {
        var valueFound = false;
        for (var i = 0; i < array.length; i++) {
            if (array[i] == value) {
                valueFound = true;
                break;
            }
        }
        return valueFound;
    }

    function arrayIndexOf(array, value) {
        var index = -1;
        for (var i = 0; i < array.length; i++) {
            if (array[i] == value) {
                index = i;
                break;
            }
        }
        return index;
    }

    function ensureArrayContains(array, value) {
        if (!arrayContains(array, value)) {
            array.push(value);
        }
    }

    function ensureArrayDoesntContain(array, value) {
        var index = arrayIndexOf(array, value);
        if (index != -1) {
            array.splice(index, 1);
        }
    }

    function getFirstKeyFromHashTable(hashTable) {

        var firstKey = null;
        for (var key in hashTable) {
            firstKey = key;
            break;
        }

        return firstKey;
    }

    function getHashTableKeys(hashTable) {

        var keys = [];
        for (var key in hashTable) {
            keys.push(key);
        }

        return keys;
    }

    function clamp(value, min, max) {

        if (value < min) {
            value = min;
        } else if (value > max) {
            value = max;
        }

        return value;
    }

    function min(v1, v2) {
        return v1 < v2 ? v1 : v2;
    }

    function max(v1, v2) {
        return v1 > v2 ? v1 : v2;
    }

    function lerp(from, to, t) {
        return from + ((to - from) * t);
    }

    function gimme(value, target, delta) {
        return Math.abs(target - value) > delta ? value : target;
    }

    function log(gui, message) {

        console.log(message);

        var logContainer = gui.find('[data-role="log"]').append(message + '<br />');

        var height = logContainer[0].scrollHeight;
        logContainer.scrollTop(height);
    }

})(jQuery);