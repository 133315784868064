var bizTweet = {

	loadConfig: function () {

		var config = null;

		var configElement = document.getElementById('biztweet-config');

		if (configElement == null) {

			console.error('BizTweet config element not found.');

		} else {

			config = {
				facebookAppId: configElement.getAttribute('data-facebook-app-id'),
				facebookPageId: configElement.getAttribute('data-facebook-page-id'),
				twitterUserName: configElement.getAttribute('data-twitter-username')
			}
		}

		return config;
	},

	outputFacebookRegistrationButton: function (formattedFlightDate, flightNumber, containerElementId, optIn) {

		var config = this.loadConfig();
		
		if (config != null) {
			
			var optCode = optIn ? "302" : "201";
			
			var dataRef = 
				(formattedFlightDate + '|' + flightNumber + '|' + optCode)
				.replace(/\s/g, "SPC")
				.replace(/\|/g, "PIPE");

			var html =
				'<div class="fb-send-to-messenger" ' +
				'	messenger_app_id="' + config.facebookAppId + '" ' +
				'	page_id="' + config.facebookPageId + '" ' +
				'	data-ref="' + dataRef + '" ' +
				'	color="blue" ' +
				'	size="xlarge">' +
				'</div>'

			var containerElement = document.getElementById(containerElementId);
			containerElement.innerHTML = html;

			FB.XFBML.parse(containerElement);
		}
	},

	launchTwitterRegistrationPopup: function (formattedFlightDate, flightNumber, optIn) {

		var config = this.loadConfig();

		var optCode = optIn ? "301" : "101";
		
		if (config != null) {

			var message =
				'Please register me for flight updates on flight {flight-number} on {flight-date} RSRC:{optIn-Code}'
				.replace('{flight-date}', formattedFlightDate)
				.replace('{flight-number}', flightNumber)
				.replace('{optIn-Code}', optCode);

			var url = 
				'http://twitter.com/intent/tweet?text=' +
				encodeURIComponent('@' + config.twitterUserName + ' ' + message);

			window.open(url, 'Tweet To Register', 'height=300, width=550', '_parent');	
		}
	}
}