$(document).ready(function () {
    $('#cashRadioBtn').attr("checked", "checked");

    var hiddenproductTypeCode = $('#productTypeCode'),
        hiddencurrencyCode = $('#currencyCode'),
        hiddenforeignCurrencyAmount = $('#foreignCurrencyAmount'),
        allCurrencyOptions = $("#currencySelection").html(),
        selectedProductType = $('#defaultCurrencyProduct').val(),
        defaultCurrencyCode = $('#defaultForeignCurrency').val();

    FilterCurrencyDropDown(selectedProductType);
    SetDefaultCurrencySelection();
    CalculateCurrencyValue();

    $('input[type=radio][name=currencyRadioButton]').change(function () {
        ShowAvailableCurrencies();
        CalculateCurrencyValue();
    });

    $('#currencySelection').change(function () {
        CalculateCurrencyValue();
    });

    $("#currencyAmount").keyup(function () {
        CalculateCurrencyValue();
    });

    $('#currencyBtn').click(function (e) {
        var isValid = ValidateAmount();
        if (isValid === true) {
            $('#currencyForm').submit();
        }
    });

    function ValidateAmount() {
        var amount = parseFloat($('#currencyAmount').val());
        if (isNaN(amount) || amount <= 0) {
            DisplayCurrencyError();

            return false;
        }

        return true;
    }

    function FilterCurrencyDropDown(productType) {
        var selectedCurrency = $("#currencySelection").val();
        var selectedCurrencyText = $("#currencySelection option:selected").text();
        $("#currencySelection").html(allCurrencyOptions);
        $('#currencySelection :not([data-product-type^="' + productType + '"])').remove();

        //alert(selectedCurrency)



        if (selectedCurrency.indexOf("MCP") >= 0) {
            $("#currencySelection").val(selectedCurrency.replace('MCP_', ''));
            setTimeout(function () { $("#select2-currencySelection-container").text(selectedCurrencyText); }, 1)
        }
        else {
            if ($("#currencySelection option[value=" + 'MCP_' + selectedCurrency + "]").length > 0) {
                $("#currencySelection").val('MCP_' + selectedCurrency);
                setTimeout(function () { $("#select2-currencySelection-container").text(selectedCurrencyText); }, 1)
            }
            else {
                SetDefaultCurrencySelection();
            }
        }

    }

    function ShowAvailableCurrencies() {
        selectedProductType = $('input[name=currencyRadioButton]:checked', '#searchCurrency').val();
        FilterCurrencyDropDown(selectedProductType);
        // SetDefaultCurrencySelection();
    }

    function CalculateCurrencyValue() {

        var amount = parseFloat($('#currencyAmount').val());

        $('#currencyRateDisplayError').hide();
        if (!isNaN(amount) && amount > 0) {
            var rate = parseFloat($("#currencySelection :selected").data('currency-rate'));
            var currencyValue = (amount * rate);
            SetCurrencyFormFields(currencyValue, amount);

        }
        else {
            DisplayCurrencyError();
        }
    }

    function SetCurrencyFormFields(currencyValue, amount) {
        var productType = $("#currencySelection :selected").data('product-type');
        var currencyCode = $("#currencySelection :selected").data('currency-code');

        $('#currencyValueLabel').text(currencyValue.toFixed(2));
        $('#currencyCodeLabel').text(currencyCode.replace("MCP_", ""));

        hiddenproductTypeCode.val(productType);

        if (productType === "MCD") {
            hiddencurrencyCode.val("SCP" + currencyCode);
        }
        else {
            hiddencurrencyCode.val(currencyCode);
        }
        hiddenforeignCurrencyAmount.val(amount);
    }

    function DisplayCurrencyError() {
        $('#currencyRateDisplayError').html('Invalid Amount').show();
    }

    function SetDefaultCurrencySelection() {
        if ($("input[name='currencyRadioButton']:checked").val() === "PrePaidCardSale") {
            $("#currencySelection").val("MCP_" + defaultCurrencyCode);
            return;
        }

        $("#currencySelection").val(defaultCurrencyCode);
    }
});




$(document).ready(function () {
    if ($('#currencySelectionHero').length == 0) {
        return;
    }
    $('#cashRadioBtnHero').attr("checked", "checked");

    var hiddenproductTypeCode = $('#productTypeCodeHero'),
        hiddencurrencyCode = $('#currencyCodeHero'),
        hiddenforeignCurrencyAmount = $('#foreignCurrencyAmountHero'),
        allCurrencyOptions = $("#currencySelectionHero").html(),
        selectedProductType = $('#defaultCurrencyProductHero').val(),
        defaultCurrencyCode = $('#defaultForeignCurrencyHero').val();

    FilterCurrencyDropDown(selectedProductType);
    SetDefaultCurrencySelection();
    CalculateCurrencyValue();

    $('input[type=radio][name=currencyRadioButtonHero]').change(function () {
        ShowAvailableCurrencies();
        CalculateCurrencyValue();
    });

    $('#currencySelectionHero').change(function () {
        CalculateCurrencyValue();
    });

    $("#currencyAmountHero").keyup(function () {
        CalculateCurrencyValue();
    });

    $('#currencyBtnHero').click(function (e) {
        var isValid = ValidateAmount();
        if (isValid === true) {
            $('#currencyFormHero').submit();
        }
    });

    function ValidateAmount() {
        var amount = parseFloat($('#currencyAmountHero').val());
        if (isNaN(amount) || amount <= 0) {
            DisplayCurrencyError();

            return false;
        }

        return true;
    }

    function FilterCurrencyDropDown(productType) {
        var selectedCurrency = $("#currencySelectionHero").val();
        var selectedCurrencyText = $("#currencySelectionHero option:selected").text();
        $("#currencySelectionHero").html(allCurrencyOptions);
        $('#currencySelectionHero :not([data-product-type^="' + productType + '"])').remove();


        if (selectedCurrency.indexOf("MCP") >= 0) {
            $("#currencySelectionHero").val(selectedCurrency.replace('MCP_', ''));
            setTimeout(function () { $("#select2-currencySelectionHero-container").text(selectedCurrencyText); }, 1)
        }
        else {
            if ($("#currencySelectionHero option[value=" + 'MCP_' + selectedCurrency + "]").length > 0) {
                $("#currencySelectionHero").val('MCP_' + selectedCurrency);
                setTimeout(function () { $("#select2-currencySelectionHero-container").text(selectedCurrencyText); }, 1)
            }
            else {
                SetDefaultCurrencySelection();
            }
        }

    }

    function ShowAvailableCurrencies() {
        selectedProductType = $('input[name=currencyRadioButtonHero]:checked', '#searchCurrencyHero').val();
        FilterCurrencyDropDown(selectedProductType);
        // SetDefaultCurrencySelection();
    }

    function CalculateCurrencyValue() {

        var amount = parseFloat($('#currencyAmountHero').val());

        $('#currencyRateDisplayErrorHero').hide();
        if (!isNaN(amount) && amount > 0) {
            var rate = parseFloat($("#currencySelectionHero :selected").data('currency-rate'));
            var currencyValue = (amount * rate);
            SetCurrencyFormFields(currencyValue, amount);

        }
        else {
            DisplayCurrencyError();
        }
    }

    function SetCurrencyFormFields(currencyValue, amount) {
        var productType = $("#currencySelectionHero :selected").data('product-type');
        var currencyCode = $("#currencySelectionHero :selected").data('currency-code');

        $('#currencyValueLabelHero').text(currencyValue.toFixed(2));
        $('#currencyCodeLabelHero').text(currencyCode.replace("MCP_", ""));

        hiddenproductTypeCode.val(productType);

        if (productType === "MCD") {
            hiddencurrencyCode.val("SCP" + currencyCode);
        }
        else {
            hiddencurrencyCode.val(currencyCode);
        }
        hiddenforeignCurrencyAmount.val(amount);
    }

    function DisplayCurrencyError() {
        $('#currencyRateDisplayErrorHero').html('Invalid Amount').show();
    }

    function SetDefaultCurrencySelection() {
        if ($("input[name='currencyRadioButtonHero']:checked").val() === "PrePaidCardSale") {
            $("#currencySelectionHero").val("MCP_" + defaultCurrencyCode);
            return;
        }

        $("#currencySelectionHero").val(defaultCurrencyCode);
    }
});





$(document).ready(function () {
    $('#cashRadioBtnMobile').attr("checked", "checked");

    var hiddenproductTypeCode = $('#productTypeCodeMobile'),
        hiddencurrencyCode = $('#currencyCodeMobile'),
        hiddenforeignCurrencyAmount = $('#foreignCurrencyAmountMobile'),
        allCurrencyOptions = $("#currencySelectionMobile").html(),
        selectedProductType = $('#defaultCurrencyProductMobile').val(),
        defaultCurrencyCode = $('#defaultForeignCurrencyMobile').val();

    FilterCurrencyDropDown(selectedProductType);
    SetDefaultCurrencySelection();
    CalculateCurrencyValue();

    $('input[type=radio][name=currencyRadioButtonMobile]').change(function () {
        ShowAvailableCurrencies();
        CalculateCurrencyValue();
    });

    $('#currencySelectionMobile').change(function () {
        CalculateCurrencyValue();
    });

    $("#currencyAmountMobile").keyup(function () {
        CalculateCurrencyValue();
    });

    $('#currencyBtnMobile').click(function (e) {
        var isValid = ValidateAmount();
        if (isValid === true) {
            $('#currencyFormMobile').submit();
        }
    });

    function ValidateAmount() {
        var amount = parseFloat($('#currencyAmountMobile').val());

        if (isNaN(amount) || amount <= 0) {
            DisplayCurrencyError();

            return false;
        }

        return true;
    }

    function FilterCurrencyDropDown(productType) {
        var selectedCurrency = $("#currencySelectionMobile").val();
        var selectedCurrencyText = $("#currencySelectionMobile option:selected").text();
        $("#currencySelectionMobile").html(allCurrencyOptions);
        $('#currencySelectionMobile :not([data-product-type^="' + productType + '"])').remove();

        //alert(selectedCurrency)



        if (selectedCurrency.indexOf("MCP") >= 0) {
            $("#currencySelectionMobile").val(selectedCurrency.replace('MCP_', ''));
            setTimeout(function () { $("#select2-currencySelectionMobile-container").text(selectedCurrencyText); }, 1)
        }
        else {
            if ($("#currencySelectionMobile option[value=" + 'MCP_' + selectedCurrency + "]").length > 0) {
                $("#currencySelectionMobile").val('MCP_' + selectedCurrency);
                setTimeout(function () { $("#select2-currencySelectionMobile-container").text(selectedCurrencyText); }, 1)
            }
            else {
                SetDefaultCurrencySelection();
            }
        }

    }

    function ShowAvailableCurrencies() {
        selectedProductType = $('input[name=currencyRadioButtonMobile]:checked', '#searchCurrencyMobile').val();
        FilterCurrencyDropDown(selectedProductType);
        // SetDefaultCurrencySelection();
    }

    function CalculateCurrencyValue() {

        var amount = parseFloat($('#currencyAmountMobile').val());
        $('#currencyRateDisplayErrorMobile').hide();
        if (!isNaN(amount) && amount > 0) {
            var rate = parseFloat($("#currencySelectionMobile :selected").data('currency-rate'));
            var currencyValue = (amount * rate);
            SetCurrencyFormFields(currencyValue, amount);

        }
        else {
            DisplayCurrencyError();
        }
    }

    function SetCurrencyFormFields(currencyValue, amount) {
        var productType = $("#currencySelectionMobile :selected").data('product-type');
        var currencyCode = $("#currencySelectionMobile :selected").data('currency-code');

        $('#currencyValueLabelMobile').text(currencyValue.toFixed(2));
        $('#currencyCodeLabelMobile').text(currencyCode.replace("MCP_", ""));

        hiddenproductTypeCode.val(productType);

        if (productType === "MCD") {
            hiddencurrencyCode.val("SCP" + currencyCode);
        }
        else {
            hiddencurrencyCode.val(currencyCode);
        }
        hiddenforeignCurrencyAmount.val(amount);
    }

    function DisplayCurrencyError() {
        $('#currencyRateDisplayErrorMobile').html('Invalid Amount').show();
    }

    function SetDefaultCurrencySelection() {
        if ($("input[name='currencyRadioButtonMobile']:checked").val() === "PrePaidCardSale") {
            $("#currencySelectionMobile").val("MCP_" + defaultCurrencyCode);
            return;
        }

        $("#currencySelectionMobile").val(defaultCurrencyCode);
    }
});